import React from 'react'
import MyPhoto from '../images/photo-3.jpg'

function about(props) {
    return (
        <section id="aboutUs">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 tileBox mobile-image"> <img src={MyPhoto} alt="me" /> </div>
                    <div className="col-md-8 tileBox mobile-header">
                        <div className="txtHead" style={{ textAlign: 'center' }}>
                            <h2 dangerouslySetInnerHTML={{ __html: props.aboutData.aboutTitle }}></h2>
                            <h3 dangerouslySetInnerHTML={{ __html: props.aboutData.aboutSubTitle }}></h3>
                        </div>
                        <p style={{ textAlign: 'justify', marginTop: '15px' }} dangerouslySetInnerHTML={{ __html: props.aboutData.abouttext }}>

                        </p>
                        <div>
                            <a href="#contactUs" className="hireMe2">CONTACT ME</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default about