import React, { useState, useEffect } from 'react'
import axios from 'axios';

function Testimonials() {

    const [testimonials, setTestimonials] = useState([]);

    const getData = async () => {
         const json = await axios.get(
          `http://rest-api.net/profile/gettestimonials`
        );
        return json;
     };
    
    useEffect(() => {
        getData().then(res => {
            setTestimonials(JSON.parse(JSON.stringify(res.data)))
        })
    }, []);

    return (
        <section id="testimonials" className="secPad">
            <div className="container">
                <div className="heading">
                    <h2>My Praises</h2>
                </div>
                <div className="row" style={{ paddingLeft: "15px", paddingRight: "15px" }}>

                    {testimonials.map((praise) => (
                        <div key={praise.id} className="col-md-4  testimonialBody">
                            <div dangerouslySetInnerHTML={{ __html: praise.testimonial1 }}></div>
                            <div className="testimonialSignature">{praise.author}</div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Testimonials