import React from 'react'

function Footer() {
    return (
        <>
            <footer className="copyright" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="social pull-left"> <a href="https://www.linkedin.com/in/seansayed/" target="_blank"><i className="fa fa-linkedin"></i></a> <a href="https://twitter.com/seansayed" target="_blank"><i className="fa fa-twitter"></i></a> <a href="#"><i className="fa fa-facebook"></i></a> <a href="https://www.instagram.com/sean.sayed/" target="_blank"><i className="fa fa-instagram"></i></a> <a href="https://github.com/sean-sayed/" target="_blank"><i className="fa fa-github"></i></a> </div>
                        </div>
                        <div className="col-md-6"> <span className="pull-right">Copyright 2022 © | All Rights Reserved  -- Sean Sayed</span> </div>
                    </div >
                </div>
            </footer >
            <a href="#top" className="topHome"><i className="fa fa-chevron-up fa-2x"></i></a>
        </>
    )
}

export default Footer