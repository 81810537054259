import React, { Component } from "react";
import video from '../images/video-1.mp4'

const SiteHeader = (props) => {
    return (<>
        <section id="home">
            <div className="banner-container">
                <div style={{ height: '1080px', top: 0, right: 0, bottom: 0, left: 0, zIndex: -100, overflowX: "hidden" }}>
                    <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: -1 }}>
                        <video src={video} loop autoPlay muted playsInline>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                <div className="container banner-content">
                    <div className="row">
                        <header className="centered">
                            <h1 id="main-name">{props.headerData.name}</h1>
                            <h1 id="mobile-name">{props.headerData.mobileName}</h1>
                            <p style={{ lineHeight: '42px', marginTop: '25px' }}>{props.headerData.title}</p>
                        </header>
                        <div className="social-icons">
                            <a href="https://www.linkedin.com/in/seansayed/" target="_blank"><i className="fa fa-linkedin-square"></i></a>
                            <a href="https://twitter.com/seansayed" target="_blank"><i className="fa fa-twitter"></i></a>
                            <a href="https://www.instagram.com/sean.sayed/" target="_blank"><i className="fa fa-instagram"></i></a>
                        </div>
                        <a href="#contactUs" className="hireMe">CONTACT ME</a>
                    </div>
                </div>

            </div>
        </section>
    </>);
}

export default SiteHeader;