import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import $ from "jquery";

import "./css/bootstrap.min.css";
import "./css/isotope.css";
import "./css/fancybox/jquery.fancybox.css";
import "./css/da-slider.css";
import "./css/styles.css";
import "./css/plugin-css/jquery.fullPage.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);