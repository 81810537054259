import React from 'react'

function Journey() {
    return (
        <section id="journey" className="secPad" style={{ marginBottom: "-120px", backgroundColor: "#000", color: "#fff" }}>
            <div className="container">
                <div className="heading ">
                    <h2>My Journey</h2>

                    <div className="journey-main" style={{ width: "100%", marginTop: "65px" }}>
                        <div className="row" style={{ marginLeft: "0" }}>
                            <div className="journeyMedal">2010</div>
                            <div className="progress-bar" style={{ backgroundColor: "#5887FF", width: "40%", height: "10px", borderRadius: "0px", marginTop: "5px", float: "left" }}><span style={{ fontSize: "16px", fontWeight: "700" }}><br />DataBid&trade;</span></div>
                            <div className="journeyMedal">2016</div>
                            <div className="progress-bar" style={{ width: "14%", height: "10px", borderRadius: "0px", marginTop: "5px", float: "left" }}><span style={{ fontSize: "16px", fontWeight: "700" }}><br />Airgo Miles&trade;</span></div>
                            <div className="journeyMedal">2020</div>
                            <div className="progress-bar" style={{ width: "14%", height: "10px", borderRadius: "0px", marginTop: "5px", float: "left" }}><span style={{ fontSize: "16px", fontWeight: "700" }}><br />PlanBGroup&trade;</span></div>
                            <div className="journeyMedal" style={{ padding: "25px 0 0 8px" }}>Present</div>
                        </div>
                    </div>

                    <div className="journey-mobile" style={{ width: "100%", marginTop: "35px", display: "none" }}>
                        <div className="row" style={{ marginLeft: "115px" }}>
                            <div className="journeyMedal">2010</div>
                            <div style={{ backgroundColor: "#5887FF", width: "10px", height: "100px", borderRadius: "0px", marginLeft: "34px" }}><span style={{ fontSize: "16px", fontWeight: "700" }}><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DataBid&trade;</span></div>
                            <div className="journeyMedal">2016</div>
                            <div style={{ backgroundColor: "#5887FF", width: "10px", height: "40px", borderRadius: "0px", marginLeft: "34px" }}><span style={{ fontSize: "16px", fontWeight: "700" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Airgo&nbsp;Miles&trade;</span></div>
                            <div className="journeyMedal">2020</div>
                            <div style={{ backgroundColor: "#5887FF", width: "10px", height: "40px", borderRadius: "0px", marginLeft: "34px" }}><span style={{ fontSize: "16px", fontWeight: "700" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PlanBGroup&trade;</span></div>
                            <div className="journeyMedal" style={{ padding: "25px 0 0 8px" }}>Present</div>
                        </div>
                    </div>

                </div>
                <div style={{ textAlign: "center", marginTop: "65px", marginBottom: "120px", fontSize: "20px", color: "#aaa", fontStyle: "italic" }}>
                    "A journey of a thousand miles begins with a single step." - Lao Tzu
                </div>
            </div>
        </section>
    )
}

export default Journey