import React, { useRef, useEffect } from 'react'

function SiteTitle(title) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = title;
    }, [title]);
}

export default SiteTitle