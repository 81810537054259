import React from 'react'

function Quote(props) {
    return (
        <section id="quote" className="bg-parlex">
            <div className="parlex-back">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: props.quoteData.quoteText }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Quote