import React from 'react'

function Contact() {
    return (
        <section id="contactUs" className="page-section secPad">
            <div className="container">
                <div className="row">
                    <div className="heading">
                        <h2 style={{ color: "#aaa" }}>Let's Keep In Touch!</h2>
                        <p>Thank you for visiting, and remember I'm only as far as the form below.</p>
                    </div>
                </div>
                <div className="row mrgn30" style={{ color: "#aaa" }}>
                    <form method="post" action="../ajax/SendMail.aspx" fromemail="me@seansayed.com" fromname="Sean Sayed" id="contact-form" role="form">

                        <div className="message col-xs-12">
                            <p className="email-loading"><img src="../images/loading.gif" alt="" />&nbsp;&nbsp;&nbsp;Sending...</p>
                            <p className="email-success"><i className="icon icon-icon-check-alt2"></i> Your message has successfully been sent.</p>
                            <p className="email-failed"><i className="icon icon-icon-close-alt2"></i> Something went wrong!</p>
                        </div>

                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" className="form-control" name="contact-name" id="contact-name" placeholder="Enter name" title="Please enter your name (at least 2 characters)" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" name="contact-email" id="contact-email" placeholder="Enter email" title="Please enter a valid email address" required />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="comments">Comments</label>
                                <textarea name="contact-message" className="form-control" id="contact-message" cols="3" rows="5" placeholder="Enter your message…" title="Please enter your message (at least 10 characters)" required></textarea>
                            </div>
                            <button name="submit" type="submit" className="btn btn-lg btn-primary" id="submit">Submit</button>
                            <div className="result"></div>
                        </div>
                    </form>
                    <div className="col-sm-4">
                        <h4>Email:</h4>
                        <address>
                            Sean Sayed<br />
                            <div>
                                <a href="mailto:me@seansayed.com">me@seansayed.com</a><br />
                                <h4>Phone:</h4>
                                <address>
                                    (951) 575-0400<br />
                                </address>
                            </div>
                        </address>
                    </div>
                </div>
            </div >
        </section >
    )
}

export default Contact