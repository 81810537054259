import React, { useState, useEffect } from 'react'
import axios from 'axios';

function Experience() {

    const [experience, setExperience] = useState([]);

    const getData = async () => {
         const json = await axios.get(
          `http://rest-api.net/profile/getclients`
        );
        return json;
     };
    
    useEffect(() => {
        getData().then(res => {
            setExperience(JSON.parse(JSON.stringify(res.data)))
        })
    }, []);

    return (
        <section id="experience" className="secPad">
            <div className="container">
                <div className="heading">
                    <h2>My Experience</h2>
                    <br />
                    <h4>15+ Years Experience!</h4>
                    <p>I've had the good fortune to be called upon to develop applications for a vast number of some truly amazing companies, both here in the US & abroad, encompassing a plethora of diverse diciplines & fields. From entertainment to education to e-commerce to medical to financial. Each one bringing with it it's own complexities & challenges, to be recognized and resolved. Each one accomplished succesfully, ontime & under budget, leaving behind nothing but a satisfied client.<br /><br />Here is a showcase of just a few of the happy clients.</p>
                </div>

                <div className="row">
                    {experience.map(exp =>  
                        <div key={exp.name} className="col-md-3 col-sm-6">
                            <div className="exp-wrapper">
                                <img src={exp.logo} alt={exp.alt} />
                            </div>
                        </div>
                    )}
                </div>

                <div style={{ textAlign: "center", marginTop: "35px", fontSize: "20px", color: "#3477a4", fontStyle: "italic" }}>

                </div>
            </div>

        </section>
    )
}

export default Experience