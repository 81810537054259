import React, { useState, useEffect } from 'react'
import axios from 'axios';
import HTML5Icon from '../images/icons/html.png'
import CSSIcon from '../images/icons/css.png'
import ReactIcon from '../images/icons/react.png'
import AngularIcon from '../images/icons/angular.png'
import JsIcon from '../images/icons/js.png'
import AjaxIcon from '../images/icons/ajax.png'
import BootstrapIcon from '../images/icons/bootstrap.png'
import NodeIcon from '../images/icons/node.png'
import CSharpIcon from '../images/icons/csharp.png'
import ASPNetIcon from '../images/icons/asp.net.png'
import MVCIcon from '../images/icons/mvc.png'
import CoreIcon from '../images/icons/core.png'
import JavaIcon from '../images/icons/java.png'
import PythonIcon from '../images/icons/python.png'
import MSSqlIcon from '../images/icons/mssql.png'
import MySqlIcon from '../images/icons/mysql.png'
import MongoIcon from '../images/icons/mongo.png'
import CloudServicesIcon from '../images/icons/cloud-services.png'
import SkillsMedals from "./SkillsMedals";

function Skills(props) {

    const [skills, setSkills] = useState([]);

    const getData = async () => {
         const json = await axios.get(
          `http://rest-api.net/profile/getskills`
        );
        return json;
     };
    
    useEffect(() => {
        getData().then(res => {
            setSkills(JSON.parse(JSON.stringify(res.data)))
        })
    }, []);

    return (
        <section id="skills" className="secPad white">
            <div className="container">
                <div className="heading ">
                    <h2>My Strongpoints</h2>
                    <div style={{ width: '100%' }}>
                        <div className="skills-subtitle">Extremely Detail-oriented:</div>
                        <div className="skills-text">One line of code, one misspelled word, all can have a significant impact on your website’s functionality and or appearance, and as it's developer I'm always on top of it to make sure that I'm not missing any of the key details.</div>
                        <div className="clear-both"></div>
                        <div className="skills-subtitle">Great at Multitasking:</div>
                        <div className="skills-text">As any programmer worth their salt knows that situations do occur during development, and they seldom happen one at a time. As a seasoned developer I know just how to overcome them without missing any deadlines & still deliver quality work.</div>
                        <div className="clear-both"></div>
                        <div className="skills-subtitle">Very Self-motivated:</div>
                        <div className="skills-text">I have a simple philosophy - good work begets more work.  In other words, the more I invest on a clients project and exceed their expectations, the happier the client will be. Setting goals, accomplishing them, and seeing progress being made on your project encourages me to do more without any external stimulus.</div>
                        <div className="clear-both"></div>
                        <div className="skills-subtitle">Logical & Good at Problem-solving:</div>
                        <div className="skills-text">Websites need to be functional as well as attractive, and sometimes a clients’ needs in these regards might not always be easy to meet. As a developer I'm very good at figuring out how to translate a client’s vision to an actual, functional website - with all their requirements intact.</div>
                        <div className="clear-both"></div>
                        <div className="skills-subtitle">Patient & Cool under pressure:</div>
                        <div className="skills-text">Tight deadlines are common when developing websites & I'm no stranger to them. A good developer needs to be able to handle the pressure of having the work done when needed & still provide a final product that they can be proud of.</div>
                        <div className="clear-both"></div>
                    </div>

                    <div style={{ textAlign: "center", marginTop: "35px", fontSize: "20px", color: "#3477a4", fontStyle: "italic" }}>
                        "What if I fall? Oh, but my darling, what if you fly?" ― Erin Hanson.
                    </div>
                </div>
                <div className="row skillContainer">
                    <SkillsMedals id="diagram-id-1" title="Visual Designer" strength="90.0%" years="Exp 12+ years" />
                    <SkillsMedals id="diagram-id-2" title="Frontend Developer" strength="95.0%" years="Exp 13+ years" />
                    <SkillsMedals id="diagram-id-3" title="Backend Developer" strength="95.0%" years="Exp 15+ years" />
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <h2>Programming Skills</h2>
                        <div style={{ width: "100%" }}>
                            <div style={{ textAlign: "left", height: "35px", fontSize: "16px", fontWeight: "700", paddingTop: "6px", color: "#5887a0", borderBottom: "solid 2px #000" }}>Front End - Presentation Logic</div>

                            <div style={{ padding: "5px 10px 10px 5px", width: "100%" }}>
                                <div style={{ textAlign: "center" }}>
                                    <img className="pad5px" src={HTML5Icon} data-toggle="tooltip" data-placement="top" title="HTML 5" />
                                    <img className="pad5px" src={CSSIcon} data-toggle="tooltip" data-placement="top" title="CSS" />
                                    <img className="pad5px" src={ReactIcon} data-toggle="tooltip" data-placement="top" title="React JS" />
                                    <img className="pad5px" src={AngularIcon} data-toggle="tooltip" data-placement="top" title="Angular JS" />
                                    <img className="pad5px" src={JsIcon} data-toggle="tooltip" data-placement="top" title="Javascript" />
                                    <img className="pad5px" src={AjaxIcon} data-toggle="tooltip" data-placement="top" title="AJAX" />
                                    <img className="pad5px" src={BootstrapIcon} data-toggle="tooltip" data-placement="top" title="Bootstrap" />
                                    <img className="pad5px" src={NodeIcon} data-toggle="tooltip" data-placement="top" title="Node JS" />
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>

                        <div style={{ width: "100%", marginTop: "25px" }}>
                            <div style={{ textAlign: "left", height: "35px", fontSize: "16px", fontWeight: "700", paddingTop: "6px", color: "#588760", borderBottom: "solid 2px #000" }}>Middle Tier - Business Logic</div>

                            <div style={{ padding: "5px 10px 10px 5px" }}>
                                <div style={{ textAlign: "center", width: "100%" }}>
                                    <img className="pad5px" src={CSharpIcon} data-toggle="tooltip" data-placement="top" title="C#" />
                                    <img className="pad5px" src={ASPNetIcon} data-toggle="tooltip" data-placement="top" title="ASP.NET" />
                                    <img className="pad5px" src={MVCIcon} data-toggle="tooltip" data-placement="top" title=".NET MVC" />
                                    <img className="pad5px" src={CoreIcon} data-toggle="tooltip" data-placement="top" title=".NET CORE" />
                                    <img className="pad5px" src={JavaIcon} data-toggle="tooltip" data-placement="top" title="JAVA" />
                                    <img className="pad5px" src={PythonIcon} data-toggle="tooltip" data-placement="top" title="Python" />
                                </div>
                                <div className="clear-both"></div>
                            </div>

                        </div>

                        <div style={{ width: "100%", marginTop: "25px" }}>
                            <div style={{ textAlign: "left", height: "35px", fontSize: "16px", fontWeight: "700", paddingTop: "6px", color: "#780700", borderBottom: "solid 2px #000" }}>Back End - Data Access Logic</div>

                            <div style={{ padding: "5px 10px 10px 5px" }}>
                                <div style={{ textAlign: "center", width: "100%" }}>
                                    <img className="pad5px" src={MSSqlIcon} data-toggle="tooltip" data-placement="top" title="MS SQL" />
                                    <img className="pad5px" src={MySqlIcon} data-toggle="tooltip" data-placement="top" title="My SQL" />
                                    <img className="pad5px" src={MongoIcon} data-toggle="tooltip" data-placement="top" title="Mongo NoSQLL" />
                                    <img className="pad5px" src={CloudServicesIcon} data-toggle="tooltip" data-placement="top" title="Cloud Services" />
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        {skills.map((strengths) => (
                            <div key={strengths.name} className="row">
                                <div className="col-md-3 skilltitle"><b>{strengths.name}</b></div>
                                <div className="col-md-9">
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{ width: `${strengths.percent}%` }}> <span className="sr-only">90% Complete</span> </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

            <div style={{ textAlign: "center", marginTop: "35px", fontSize: "20px", color: "#3477a4", fontStyle: "italic" }}>
                "Beware of programmers who carry screwdrivers."
            </div>

        </section>
    )
}

export default Skills