import React from 'react'

function Who(props) {
    return (
        <section id="whoiam" className="secPad white" style={{ marginBottom: '-120px' }}>
            <div className="container">
                <div className="heading ">
                    <h2>Who I am</h2>
                    <div style={{ width: '100%' }}>
                        <div dangerouslySetInnerHTML={{ __html: props.whoData.whoText }}>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center', marginTop: '-15px', marginBottom: '65px', fontSize: '20px', color: '#3477a4', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: props.whoData.whoFooter }} >
            </div>
        </section>
    )
}

export default Who