import React, { useState, useEffect } from 'react'
import axios from 'axios';
import $ from "jquery";

function Portfolio() {

const [myportfolio, setMyportfolio] = useState([]);

    const getData = async () => {
         const json = await axios.get(
          `http://rest-api.net/profile/getportfolios`
        );
        return json;
     };
    
    useEffect(() => {
        try {
            getData().then(res => {
                setMyportfolio(JSON.parse(JSON.stringify(res.data)));
            })
        }
        catch (ex) {
            console.log(ex);
        }
    }, []);    

    return (
        <section id="portfolio" className="page-section section appear clearfix secPad">
            <div className="container">
                <div className="heading">
                    <h2>My Portfolio</h2>
                    <p>In my extended tenure as a developer I have been entrusted with the online presence of over 1500+ websites. I'm a devout believer in the agile methodology for software development. A real developer cannot & should not develop in a vacuum, the client should always be involved in the loop during every aspect of the development life cycle. After all it is they who know their product best. This in my experience results in the smoothest journey & best final result.<br /><br />For your consideration I present to you just a speck of my portfolio below:</p>
                </div>
                <div className="row">
                    {/* <nav id="filter" className="col-md-12">
                        <ul>
                            <li><a href="#" className="current btn-theme btn-small" data-filter="*">All</a></li>
                            <li><a href="#" className="btn-theme btn-small" data-filter=".net">.Net</a></li>
                            <li><a href="#" className="btn-theme btn-small" data-filter=".wordpress">Wordpress</a></li>
                            <button id="arrange">ARRANGE</button>
                        </ul>
                    </nav> */}
                    <div className="col-md-12">
                        <div className="row">
                            <div className="portfolio-items isotopeWrapper clearfix">
                                {myportfolio.map(_portfolio => (                                    
                                    <article key={_portfolio.name} className={`col-sm-4  isotopeItem ${_portfolio.type}`}>
                                        <div className="portfolio-item">
                                            <img src={_portfolio.image} alt="" />
                                            <div className="portfolio-desc align-center">
                                                <div className="folio-info">
                                                    <a href={_portfolio.image} className="fancybox">
                                                        <h5>{_portfolio.name}</h5>
                                                        <i className="fa fa-plus-square-o fa-2x"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Portfolio