import React from "react";
import SiteHeader from "./pages/SiteHeader";
import NavBar from "./components/NavBar";
import Intro from "./pages/Intro";
import About from "./pages/About";
import Quote from "./pages/Quote";
import Who from "./pages/Who";
import Skills from "./pages/Skills";
import Journey from "./pages/Journey";
import Experience from "./pages/Experience";
import Testimonials from "./pages/Testimonials";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";

const headerData = {
  name: "Sean.Sayed",
  mobileName: "Sean Sayed",
  title: "Professional Software Developer"
}

const introData = {
  introTitle: "Yes, I can create a beautiful responsive website for you!<br />And Yes, I am a UX geek",
  introText: "I'm a full-stack developer based in Southern California with over fifteen years of real world experience. I have maintained, developed and launched multiple projects from scratch, where I've been responsible for the development of their back-end, front-end as well as their design."
}

const aboutData = {
  aboutTitle: "Hello, my name is Sean Sayed...",
  aboutSubTitle: "I'm a Full Stack Developer Based in California.",
  aboutText: "I'm an extremely focused individual, driven & results oriented. Combining key strengths required for critical thinking and product development, with the ability to perform in fast paced, competitive environments. A creative problem solver who loves challenges. A self starter with the ability to quickly identify problems & recify the situation."
}

const quoteData = {
  quoteText: "<h2>“Dream it. Believe it. Build it.” <span>- Anonymous</span></h2>"
}

const whoData = {
  whoText: "I have lived & breathed in the Computer field for the past 15 years and specialize in complete turnkey solutions.<br /><br />In my incredible journey I have held positions as IT Director, Project Manager & Senior Lead developer for a number of major corporations including fortune 500 companies where I have implemented software solutions for the US Government, US Navy, Homeland Security, Education Institutes, Stock Exchange as well as countless public and private companies.<br /><br />I received my BS degree from the prestigious University of California, Irvine - with high honors in Information & Computer Technology.<br /> <br />I'm also a member of many honor societies including Alpha Gamma Sigma, and hold a minor in Mechanical Engineering, Electrical Engineering as well as Calculus.<br /><br />Java certified fom California State University, Fullerton, California.<br /> <br />Masters in Business Administration, National University, Irvine, California.",
  whoFooter: "\"Technology Oriented – Detail Oriented – Results Oriented\""
}

const skillsData = {
  skillsText: "<h2>“Dream it. Believe it. Build it.” <span>- Anonymous</span></h2>"
}

const App = () => {
  return (
    <div className="App">
      <NavBar headerData={headerData} />
      <SiteHeader headerData={headerData} />
      <Intro introData={introData} />
      <About aboutData={aboutData} />
      <Quote quoteData={quoteData} />
      <Who whoData={whoData} />
      <Skills skillsData={skillsData} />
      <Journey />
      <Experience />
      <Testimonials />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
