import React from 'react'

function intro(props) {
    return (
        <>
            <section id="introText">
                <div className="container" style={{ zIndex: 10000 }}>
                    <div>
                        <h1 style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: props.introData.introTitle }}></h1>
                        <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: props.introData.introText }}></p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default intro