import React from 'react'

function SkillsMedals(props) {
    return (
        <div className="col-md-4 skillBox">
            <div id={props.id} className="diagram" data-percent={props.strength}></div>
            <div className="chart-text">
                <h4>{props.title}</h4>
                <p>{props.years}</p>
            </div>
        </div >
    )
}

export default SkillsMedals